* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f4f4f4;
    touch-action: manipulation;
}

/* Navbar */

#navbar {
    background-color: #ffffff;
    width: 100vw;
    display: flex;
    align-items: center;
    -webkit-box-shadow: 0px 9px 9px 0px rgba(195, 195, 195, 0.3);
    -moz-box-shadow: 0px 9px 9px 0px rgba(195, 195, 195, 0.3);
    box-shadow: 0px 9px 9px 0px rgba(195, 195, 195, 0.3);
    justify-content: space-between;
}

#navbarLogo {
    height: 77px;
    margin-left: 16px;
}

.grey-out {
    filter: grayscale(100%);
}

/* Navigation List */

#navigationContainer {
    width: 100vw;
    height: 100vh;

    background-color: #ffffff;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    z-index: 100;
}

#navLeftAlign {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.navigationLinks {
    margin-top: 30px;
    cursor: pointer;
}

/* InstLogin / Register */

.loginContainer {
    width: 100vw;
    background-color: #f4f4f4;
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 100vh;
    overflow: auto;
    padding-bottom: 10px;
}

.loginForm {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.loginInput {
    width: 300px;
    height: 40px;
    font-size: 21px;
    margin-bottom: 20px;
}

.loginButton {
    height: 64px;
    width: 128px;
    background-color: #7cb28c;
    color: white;
    border: 0;
    font-size: 27px;
    align-self: center;
    margin-bottom: 30px;
}

.loginButton:hover {
    background-color: #5e886b;
}

.loginTextLink {
    color: black;
    font-size: 21px;
    text-decoration-color: black;
    text-decoration-line: underline;
    margin-bottom: 16px;
}

.loginTextLink:hover {
    color: #7cb28c;
    text-decoration-color: #7cb28c;
}

.loginErrorMessage {
    color: red;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    width: 300px;
    overflow-wrap: break-word;
}

.comment-box {
    font-size: 16px;
    width: 300px;
    height: 80px;
    font-size: 21px;
    margin-bottom: 20px;
}

/* Calendar */

#calendarTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
}

#calendarContainer {
    background-color: #ffffff;
    border-radius: 70px 70px 0px 0px;
    height: 120px;
    width: 100vw;
    -webkit-box-shadow: 0px -5px 15px 0px rgba(195, 195, 195, 0.3);
    -moz-box-shadow: 0px -5px 15px 0px rgba(195, 195, 195, 0.3);
    box-shadow: 0px -5px 15px 0px rgba(195, 195, 195, 0.3);
}

#courtSelectorContainer {
    display: flex;
    justify-content: center;
    padding-top: 30px;
}

.courtSelectorWrapper {
    width: 65vw;
    padding-right: 0;
}

#courtSelector {
    width: 90vw;
    height: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#courtList {
  width: 100%;
  height: 65px;
  z-index: 0;
}

.courtDiv {
    height: 65px;
    /* width: calc(40px + 2.5vw); */
    justify-content: center;
    align-items: center;
    display: flex;
    background-image: url('./assets/tennis_court.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.courtNumber {
    color: #ffffff;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

#dateSelector {
    width: 300px;
    height: auto;
    display: flex;
    justify-content: space-between;
    z-index: 0;
}

.dateArrow {
    font-size: 40px;
}

.courtArrow {
    font-size: 48px;
}

#calendar {
    background-color: #ffffff;
    display: flex;
    width: 100vw;
    justify-content: center;
    overflow-y: scroll;
    padding-bottom: 6vh;
}

.calendar-normal-height{
  height: 43vh;
}

.calendar-mobile-height{
  height: 20vh;
}

#calenderContainer {
    display: flex;
    width: 65vw;
    justify-content: space-between;
    flex-direction: row;
    height: 1800px;
    position: relative;
    margin-bottom: 100px;
}

.calenderTime {
    position: absolute;
    left: -40px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
}

.calenderRowContainer {
    width: 70px;
    /* margin-left: 10px; */
    margin: 0 auto !important;
    height: 100%;
}

.calenderEntry {
    width: 100%;
    border-radius: 15px;
    background-color: #52a859;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calenderEntryEmpty {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    /* height: 95px; */
    background-color: #f4f4f4;
    /* margin-bottom: 25px; */
    position: relative;
}

.courtDisabled {
    background: repeating-linear-gradient(
            -45deg,
            #f4f4f4,
            #f4f4f4 20px,
            #ff0000 20px,
            #ff0000 40px
    );
    opacity: 0.35;
}

.calenderBreak {
    width: 100%;
    border-radius: 15px;
    height: 20px;
    background-color: #ef6451;
    margin-bottom: 5px;
}

#clubSelectorContainer {
    width: 100vw;
    height: -o-calc(var(--vh, 1vh) * 100 -85px);
    height: -webkit-calc(var(--vh, 1vh) * 100 -85px);
    height: -moz-calc(var(--vh, 1vh) * 100 -85px);
    height: calc(var(--vh, 1vh) * 100 - 85px);
    padding-bottom: 50px;
    overflow-y: auto;
}

.clubSelectorItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    font-size: 16px;
}

.clubSelectorItem > a {
    text-decoration: none;
}

.clubSelectorText {
    font-size: 24px;
    text-align: center;
    color: black;
    text-decoration-color: black;
    text-decoration: none;
}

.clubSelectorText:hover {
    color: #7cb28c;
}

.dot {
    height: 55px;
    width: 55px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fa951e;
    font-size: 1.5rem;
}

/* Modal */

.modal-close {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 38px;
}

.modal-close .fa-secondary {
    opacity: 1
}

.modal-confirm {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 25px;
    font-size: 32px;
}

.modal-delete {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 32px;
    fill: red;
}

.modal-title {
    width: 70%;
    margin: 0px auto 30px auto;
    color: #5a9591;
    font-size: 1.3rem;
}

.modal-info-row {
    display: flex;
    margin-left: 20px;
    margin-bottom: 16px;
}

.modal-info-icon {
    height: 24px;
    margin-right: 12px;
    align-self: center;
    font-size: 28px;
}

.modal-info-text {
    align-self: center;
    font-size: 16px;
    color: #4d4d4d;
}

.modal-error-text {
    margin: 20px auto 20px auto;
    color: red;
    font-size: 1rem;
}

.modal-guest-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
    height: 25px;
    font-size: 20px;
}

.modal-guest-row input {
    width: 129px;
    height: 25px;
    font-size: 16px;
}

#spinner-container {
    height: 300px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

#app-version {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px;
    text-align: center;
    color: rgba(0, 0, 0, 0.65);
}

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity 400ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModal__Overlay--before-close {
    opacity: 0;
}

#navbar-short-name {
    font-size: 23px;
    font-weight: 400;
    margin-left: 13px;
}

/*QR Scanner*/
.qr-code-button {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    height: 72px;
    width: 72px;
    border-radius: 50px;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    background-image: url('./assets/qr-scanner.svg');
    background-position: center;
    background-size: auto;
    background-repeat: no-repeat;
}

.qr-scanner-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow-y: auto;
  height: calc(var(--vh, 1vh) * 100);
}

.qr-scanner-header {
  height: 85px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
}

.qr-scanner-title {
  /* height: 68px; */
  flex-grow: 0.3;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
}

.qr-scanner-camera_wrapper {
  height: 60%;
  width: 100%;
  background-color: #000;
}

.qr-scanner-camera {
  height: 100%;
  width: 100%;
  z-index: 999;
}

.qr-scanner-camera>div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-scanner-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.qr-code-target-frame {
  position: relative;
  height: 266px;
  width: 266px;
  border: 2px solid transparent;
  z-index: 2
}

.qr-code-target-frame::before,
.qr-code-target-frame::after,
.qr-code-target-frame>span::before,
.qr-code-target-frame>span::after {
  display: block;
  content: "";
  width: 70px;
  height: 70px;
  position: absolute;
}

.qr-code-target-frame::before {
  top: -2px;
  left: -2px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-top-left-radius: 20px;
}
.qr-code-target-frame::after {
  top: -2px;
  right: -2px;
  border-top: 2px solid white;
  border-right: 2px solid white;
  border-top-right-radius: 20px;
}
.qr-code-target-frame>span::before {
  bottom: -2px;
  left: -2px;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  border-bottom-left-radius: 20px;
}
.qr-code-target-frame>span::after {
  bottom: -2px;
  right: -2px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  border-bottom-right-radius: 20px;
}

.qr-codes-btn {
  width: 303px;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 41px;
  background-color:  #36613B;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}

.my-qr-code-background {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.my-qr-code-container {
  background-color: #fff;
  border-radius: 20px;
  /* width: 339px; */
 /*  height: calc(60% - 100px);
  min-height: 300px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 45px;
}

.my-qr-code-container h6 {
  font-size: 14px;
  font-weight: 400;
  margin-top: 20px;
}

.my-qr-code-container span {
  font-size: 18px;
  font-weight: 700;
  margin-top: 25px;
}

.swiper-container {
  z-index: 0;
  width: 100%;
}

@media screen and (max-width: 900px) and (max-height: 450px) and (orientation: landscape) {
  #calenderContainer .swiper-container {
    width: 100%;
  }

  #courtSelector .swiper-wrapper {
    margin-left: -5px;
  }

  /* #calenderContainer .swiper-wrapper {
    justify-content: space-around;
  } */

  .calenderEntryEmpty {
    width: 90%;
  }

  #courtList {
    width: 100%;
  }

  .calenderTime {
    left: -60px;
  }

  #calenderContainer {
    width: 70vw;
 }

 .courtSelectorWrapper {
    width: 70vw;
 }

 .qr-code-target-frame {
    height: 196px;
    width: 196px;
  }

  .my-qr-code-container {
    padding: 20px;
  }
}

.info-board-position {
    position: absolute;
}

.info-board-tennis-court{
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: round;
}

.info-board-tennis-court:hover {
    box-shadow: rgba(149, 157, 165, 0.8) 0px 8px 24px;
}

.info-board-tennis-court-border-container {
    position: absolute;
    border: 1px solid black;
    border-radius: 10px;
}

.info-board-tennis-court-name{
    position: absolute;
    min-width: 130px;
    padding: 1px 2px;
    background-color: white;
    text-align: center;
    border: 1px solid lightgray;
    font-weight: bold;
}

.info-board-blocked-status {
    background-color: white;
    padding: 3px;
}

#info-board-container {
    overflow: auto;
    margin-bottom: 75px;
    height: 100vh;
    width: 100vw;
}

.ad-container {
  background-color: #ffffff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5;
}

.infoboard-qr-container {
  position: absolute;
  z-index: 6;
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #000000;
  border-radius: 10px;
}

.info-board-court-border-text{
    position: absolute;
    border: 1px solid black;
    border-radius: 2px;
    left: 15%;
    padding: 0 2px;
    bottom: -1px;
}

.info-board-info-block-container {
    border: 1px solid gray;
}

.info-board-info-block-title{
    text-align: center;
    padding: 5px;
    background-color: lightgray;
    margin: 5px;
    font-size: 16px;
}

.info-board-info-block-content{
    padding: 5px;
    margin: 5px;
}

.info-board-welcome-popup-title{
    position: absolute;
    top: 6%;
    font-weight: 700;
    font-size: 35px;
    line-height: 1.4;
}

.info-board-welcome-popup-subtitle{
    position: absolute;
    top: 30%;
}

.info-board-welcome-popup-container{
    width: 150%;
    height: 150%;
    position: absolute;
    z-index: -1;
}

.info-board-welcome-popup-image {
    margin-top: 25%;
    width: 85%;
}

.info-board-reservation-popup-table-title{
    padding: 4px;
    font-size: 14px;
    background-color: black;
    color: white;
    border-radius: 2px;
    border: 0.5px solid darkgray;
}

.info-board-reservation-popup-table-text{
    padding: 4px;
    font-size: 14px;
    background-color: white;
    color: black;
    border: 0.5px solid darkgray;
}

.info-board-reservation-popup-table-cancelled-text{
    padding: 4px;
    font-size: 14px;
    background-color: indianred;
    color: white;
    border: 0.5px solid darkgray;
}

.display-flex{
    display: flex;
    flex-direction: row;
}

@media screen and (max-width: 670px) and (max-height: 400px) and (orientation: landscape) {
    .calenderEntryEmpty {
      width: 80%;
    }

    .calenderEntry{
        width: 80%;
    }

    .dot{
        width: 45px;
        height: 45px;
        font-size: large;
    }

    .header-reservation-container {
      font-size: 0.7em;
    }
    .loginContainer {
        padding-bottom: 100px;
        padding-top: 300px;
    }
}

@media screen and (max-width: 900px) and (max-height: 400px) and (orientation: landscape) {
    .loginContainer {
        margin: 0;
        padding-bottom: 100px;
        padding-top: 300px;
    }
}
